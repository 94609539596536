
.flex-col-mgap {
  display: flex;
  flex-flow: column;
  gap: 12px;
}

.full-screen-center {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 100vh;
  align-items: center;
}